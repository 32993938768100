import React from "react"
import styled from "styled-components"

//import InfoCard from "../custom/infocard"

import { Section, Container } from "../global"

import { navigate } from "gatsby"

 
const handleClick = () => {
  
  console.log('clicked get event ');
  navigate("/features/");

}

const Cards = () => (
  <Section id="cards">
    <StyledContainer>
      <Subtitle>Pricing</Subtitle>
      <SectionTitle>Pricing</SectionTitle>
      <CardsGrid>
        <CardItem>
          <CardTitle>Free</CardTitle>
          <CardText> </CardText>
          <CardText> </CardText>
 
          <CardButton onClick={()=> handleClick()}>Try it now</CardButton> 

          <CardText> </CardText>
          <CardText> </CardText>
          <CardText>Get to know a novel learning style</CardText>
    
        </CardItem>
        <CardItem>

          <CardTitle>Individual</CardTitle>
          <CardText> <b>$14.99 </b> per month </CardText>
          <CardText> </CardText>

          <CardButton onClick={()=> handleClick()}>Subscribe now</CardButton> 
          <CardText> </CardText>


          <CardText>All you can eat fun, visual and interactive learning</CardText>

        </CardItem>
        <CardItem>
          
          <CardTitle>Groups</CardTitle>
          <CardText> <b>$9.99</b>  per month for groups greater than 10. </CardText>
          <CardText> </CardText>
          <CardButton onClick={()=> handleClick()}>Subscribe now</CardButton> 


          <CardText>
          All you can eat fun, visual and interactive learning 
          </CardText>

          <CardText>
          Team collaboration and leadership board 
          </CardText>

        </CardItem>

      </CardsGrid>
    </StyledContainer>
  </Section>
)

export default Cards

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const CardsGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const CardItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F8F8F8;
`

const CardTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const CardText = styled.p`
  text-align: center;
`
const CardButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.tertiary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`